import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  error?: boolean
  name: string
  isNewsletter?: boolean
  label?: string
  required?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    {
      error = false,
      name,
      isNewsletter,
      label,
      required,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    const id = `#form-${name}${isNewsletter ? '-newsletter' : ''}`

    return (
      <Container dial={5} inline row>
        <InputField
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.0625rem;
  margin-top: 2.375rem;
  position: relative;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ label {
    &:after {
      opacity: 1;
    }
  }
`

const Label = styled.label<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding-left: 1.375rem;
  &:before,
  &:after {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    position: absolute;
    top: 0.125rem;
    left: 0;
  }
  &:before {
    border: 0.125rem solid
      ${({ error, theme }) =>
        error
          ? theme.colors.variants.dangerLight
          : theme.colors.variants.neutralDark4};
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }
`

interface ContainerProps {
  error: boolean
}
