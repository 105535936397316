import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Line } from 'app/components/Common/Line'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

export interface Props {
  description?: string
  languageCode: string
  languagePrefix?: string | null
  onContactsFormSendToBackend: ContactsFormSenderBackend
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  languageCode,
  onContactsFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      {title ? (
        <>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Line className="line" variant="compact" />
        </>
      ) : null}

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} variant="dark" />
        <Wrapper row wrap space="between">
          <Field>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              placeholder={`${useVocabularyData('name', languageCode)}*`}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              placeholder={`${useVocabularyData('lastname', languageCode)}*`}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              placeholder={useVocabularyData('email', languageCode)}
              type="email"
              {...register('email')}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.phone !== undefined}
              isDirty={formState.dirtyFields.phone}
              placeholder={`${useVocabularyData('phone', languageCode)}*`}
              type="text"
              required={true}
              {...register('phone', {
                required: true,
                validate: (phone) => phone.length > 2,
              })}
            />
          </Field>
          <Field className="large-field">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Wrapper>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 7.5rem;
  padding: 6.25rem 0;
  position: relative;
  text-align: center;

  .line {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin-top: 5.625rem;
    padding: 5rem 0;
  }

  @media (max-width: 1023px) {
    padding: 5rem 1.875rem;
  }
`

const Title = styled.h2`
  max-width: 20.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: auto;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  max-width: 38.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 3.75rem auto 0;

  p {
    margin: 1em 0;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`

const Form = styled.form`
  max-width: 42.125rem;
  margin: 4.375rem auto 0;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    max-width: none;
  }

  @media (max-width: 767px) {
    margin-top: 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  text-align: left;
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 1.875rem;
  &.large-field {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.9375rem);
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.375rem);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.1875rem;
  margin-top: 1.25rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`
