import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { Email, Phone, Pin } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  label?: string
  languageCode: string
  phone?: string
  siteName?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  label,
  languageCode,
  phone,
  siteName,
}: Props) {
  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      {siteName ? (
        <SiteName>
          {siteName}
          <Line />
        </SiteName>
      ) : null}

      <Wrapper dial={2} row wrap>
        {address ? (
          <Item className="contacts-address">
            <Pin />
            <ItemLabel>{useVocabularyData('address', languageCode)}</ItemLabel>
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          </Item>
        ) : null}

        {phone ? (
          <Item>
            <Phone />
            <ItemLabel>{useVocabularyData('phone', languageCode)}</ItemLabel>
            <Link
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              {phone}
            </Link>
          </Item>
        ) : null}

        {email ? (
          <Item>
            <Email />
            <ItemLabel>{useVocabularyData('email', languageCode)}</ItemLabel>
            {email ? (
              <Link
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {email}
              </Link>
            ) : null}
          </Item>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 14.875rem auto 8.5rem;
  padding: 0 7.292vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 11.25rem auto 5.625rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 9.375rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const SiteName = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;

  > div {
    margin: 1.5625rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 56.875rem;
  margin: 5.5rem auto 0;

  @media (max-width: 1199px) {
    max-width: none;
    margin-top: 3.75rem;
  }

  @media (max-width: 767px) {
    margin-top: 2.5rem;
  }
`

const Item = styled.div`
  width: 33.333%;
  &.contacts-address {
    svg {
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.primaryLight};
      stroke-width: 2;
    }
  }

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
    margin-bottom: 1.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
    &:first-of-type {
      margin-top: 0;
    }

    svg {
      display: none;
    }
  }
`

const ItemLabel = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  margin-bottom: 0.625rem;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.5625rem;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5625rem;
  &:hover {
    text-decoration: underline;
  }
`
